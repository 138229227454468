// ---------------------------------------------------------------------------
// GT America Condensed
// ---------------------------------------------------------------------------

@font-face {
    font-family: 'GT America Condensed';
    src: url('../../fonts/GTAmerica-CondensedRegular.woff2');
    src: url('../../fonts/GTAmerica-CondensedRegular.woff2') format('woff2'),
         url('../../fonts/GTAmerica-CondensedRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
};

// ---------------------------------------------------------------------------
// Sweet Sans Pro
// ---------------------------------------------------------------------------

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('../../fonts/SweetSansPro-Regular.woff2');
    src: url('../../fonts/SweetSansPro-Regular.woff2') format('woff2'),
         url('../../fonts/SweetSansPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
};

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('../../fonts/SweetSansPro-Italic.woff2');
    src: url('../../fonts/SweetSansPro-Italic.woff2') format('woff2'),
         url('../../fonts/SweetSansPro-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
};

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('../../fonts/SweetSansPro-Medium.woff2');
    src: url('../../fonts/SweetSansPro-Medium.woff2') format('woff2'),
         url('../../fonts/SweetSansPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
};

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('../../fonts/SweetSansPro-MediumItalic.woff2');
    src: url('../../fonts/SweetSansPro-MediumItalic.woff2') format('woff2'),
         url('../../fonts/SweetSansPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
};

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('../../fonts/SweetSansPro-Bold.woff2');
    src: url('../../fonts/SweetSansPro-Bold.woff2') format('woff2'),
         url('../../fonts/SweetSansPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
};

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('../../fonts/SweetSansPro-BoldItalic.woff2');
    src: url('../../fonts/SweetSansPro-BoldItalic.woff2') format('woff2'),
         url('../../fonts/SweetSansPro-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
};

// ---------------------------------------------------------------------------
// Plaak 1 Trial 41-Bold
// ---------------------------------------------------------------------------

@font-face {
    font-family: 'Plaak 1 Trial 41-Bold';
    src: url('../../fonts/Plaak1Trial-41-Bold.woff2');
    src: url('../../fonts/Plaak1Trial-41-Bold.woff2') format('woff2'),
         url('../../fonts/Plaak1Trial-41-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
};

// ---------------------------------------------------------------------------
// Deck
// ---------------------------------------------------------------------------

@font-face {
    font-family: 'Deck';
    src: url('../../fonts/Deck-Bold.woff2');
    src: url('../../fonts/Deck-Bold.woff2') format('woff2'),
         url('../../fonts/Deck-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
};

@font-face {
    font-family: 'Deck';
    src: url('../../fonts/Deck-Black.woff2');
    src: url('../../fonts/Deck-Black.woff2') format('woff2'),
         url('../../fonts/Deck-Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
};