@import 'mixins/font-face';
@import 'base/fonts';
@import 'base/variables';

.section-editor {
    height: 1000px;
    left: calc(59% + 188px);
    margin: 0 auto;
    position: fixed;
    top: 20vh;
    width: 375px;
    z-index: 3;

    .editor {
        margin: 0 0 20px;
        text-align: left;
        width: auto;

        .story--content,
        .feed-block--story {
            font-size: 15px;
            line-height: 1.5em;
            margin-top: 1em;
            min-height: 25px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}

.editor-container {
    h2  {
        align-items: center;
        display: flex;
        font-family: $f-headline;
        font-size: 21px;
        font-weight: normal;
        margin: 0 0 30px;
    }

    .author-link {
        cursor: pointer;
    }

    img {
        max-width: 100%;
        max-height: 20em;
    }

    .clear-option {
        align-items: center;
        background: #eee;
        border-radius: 50%;
        color: $c-dark;
        cursor: pointer;
        display: flex;
        font-family: $f-mono;
        font-size: 8px;
        font-weight: 800;
        height: 12px;
        justify-content: center;
        margin-left: 7px;
        margin-top: -2px;
        padding: 2px;
        text-transform: uppercase;
        transition: all 0.15s;
        width: 12px;
        &:hover {
            background: #ddd;
        }
    }

    .article-image {
        position: relative;
        margin-top: 0;
    }

    .article-image .clear-option {
        height: 14px;
        position: absolute;
        right: 10px;
        width: 14px;
        top: 12px;
        &:hover {
            background: $c-white;
            box-shadow: none;
        }
    }

    .feed-block--breaking {
        h2 {
            color: $c-white;
            font-family: $f-condensed;
            font-size: 40px;
            font-weight: 600;
            letter-spacing: .02em;
            line-height: 1.05em;
        }
    }
}

.editor-toolbar--hover {
    align-items: center;
    opacity: 0;
    position: fixed;
    transition: opacity 0.75s ease 0s;
    transform: translateX(60px);

    button {
        background: $c-white;
        border: 1px solid #ddd;
        box-shadow: none;
        color: $c-dark;
        cursor: pointer;
        font-family: 'Sweet Sans Pro';
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.03em;
        margin: 0;
        margin-left: -1px;
        padding: 10px 12px 8px;
        transition: all 0.25s;
        min-width: 35px;

        &:hover {
            background: #efefef;
        }

        &.button--active {
            color: #215CFF;
        }
    }
}

.feed-block h2 {
    margin: 0;
}

.editor-toolbar {
    align-items: center;
    display: flex;
    font-family: 'Sweet Sans Pro';
    padding: 10px 0;
    text-align: left;
}

.editor-toolbar--floating {
    padding: 0;
    position: absolute;
    z-index: 10;
}

.editor-toolbar--left {
    align-items: center;
    color: rgba($c-black, 0.2);
    display: flex;
    font-family: $f-mono;
    font-size: 13px;
    font-weight: 600;
}

.editor-toolbar--center {
    margin-left: 10px;
}

.editor-toolbar--right {
    margin-left: auto;
    button {
        background: $c-blue;
        border: none;
        border-radius: 30px;
        box-shadow: none;
        color: $c-white;
        cursor: pointer;
        font-family: 'Sweet Sans Pro';
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.03em;
        margin: 0;
        padding: 16px 24px 12px;
        text-transform: uppercase;
        transition: all 0.25s;
    }

    button.button--active {
        background: $c-blue;
        &:hover {
            background: #215CFF;
        }
        &:focus, &:active {
            background: #215CFF;
            outline: none;
        }
    }

    button.button--inactive {
        background: $c-blue;
        &:hover {
            background: #215CFF;
        }
        &:focus, &:active {
            background: #215CFF;
            outline: none;
        }
    }
}



.preview-container {
    margin: 0 auto;
    max-width: 42em;
}

.preview-container--extras {
    margin-top: 80px;
}

.editor-page .splash {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    width: auto;
}

.previews-container {
    min-width: 420px;
}

.meta-toolbar-container {
    background: $c-white;
    padding: 20px;

    .react-dropdown-select {
        margin: 10px 0;
        width: auto;
    }

    .react-dropdown-select-dropdown-position-bottom {
        top: 40px;
    }
}

.story--image--active {
    box-shadow: #3273dc 0px 0px 0px 2px;
}

.story--bulleted-list {
    font-family: $f-body;
    font-size: 15px;
    line-height: 1.5em;
    margin: 0;
    margin-bottom: 1em;
    padding-left: 20px;
    padding-top: 5px;

    li {
        padding-top: 10px;
    }
}

.feed-block--footer {
  padding: 0;

  .react-dropdown-select {
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-family: $f-body;
    font-size: 13px;
    line-height: 1.5em;
    min-height: 24px;

    &:focus, &:active {
        box-shadow: none;
        outline: none;
    }

    .react-dropdown-select-dropdown {
        border: 1px solid #dedede;
        border-radius: 5px;
        min-width: 200px;
        margin-left: -13px;
        padding: 5px;

        .react-dropdown-select-item {
            padding: 8px 10px 7px;
            border: none;
            border-radius: 5px;
            margin-bottom: 3px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .react-dropdown-select-content {
        align-items: center;
        display: flex;
    }

    .react-dropdown-select-input,
    .react-dropdown-select-dropdown-handle,
    .react-dropdown-select-clear {
        display: none;
    }
  }
}

.feed-block--category {
    align-items: center;
    display: flex;

    .clear-category {
        align-items: center;
        background: #eee;
        border-radius: 50%;
        color: #bbb;
        cursor: pointer;
        display: flex;
        font-size: 8px;
        font-weight: 600;
        height: 18px;
        justify-content: center;
        margin-left: 6px;
        margin-top: -3px;
        padding: 2px;
        width: 18px;

        &:hover {
            background: $c-white;
            box-shadow: 0 1px 6px rgba($c-black, 0.1);
        }
    }
}

.category-list {
    background: $c-white;
    border: 1px solid #dedede;
    border-radius: 5px;
    box-shadow: rgba($c-black, 0.15) 0px 1px 16px;
    box-sizing: border-box;
    font-family: 'Sweet Sans Pro';
    font-size: 13px;
    font-weight: 400;
    left: 0;
    list-style: none;
    margin: 8px 0 0 -14px;
    min-width: 180px;
    padding: 5px;
    position: fixed;
    top: 0;

    li {
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        height: 32px;
        border: none;
        margin-bottom: 3px;
        padding: 2px 10px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    li:hover {
        background: rgba( #3273dc, 0.15);
    }
}


.rw-dropdown-list {
    .rw-select,
    .rw-popup-container,
    input {
        display: none;
    }

    &:focus, &:active {
        box-shadow: none;
        outline: none;
    }

    &.rw-open .rw-popup-container {
        background: $c-white;
        border: 1px solid #dedede;
        border-radius: 5px;
        color: $c-dark;
        display: block;
        margin-left: -13px;
        min-width: 180px;
        padding: 5px;
        position: absolute;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        height: 32px;
        border: none;
        margin-bottom: 3px;
        padding: 2px 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &:focus, &:active {
            box-shadow: none;
            outline: none;
        }

        &:hover {
            background: rgba( #3273dc, 0.15);
        }
    }
}

.drop-target {
    align-items: center;
    background: rgba($c-white, 0.8);
    border: 1px dashed #ddd;
    color: $c-dark;
    display: flex;
    font-family: "Deck";
    font-size: 12px;
    font-weight: 600;
    height: calc(100% - 45px - 24px);
    justify-content: center;
    left: 20px;
    pointer-events: none;
    position: absolute;
    top: 20px;
    width: calc(100% - 40px);
    z-index: 5;
}