@import 'mixins/font-face';
@import 'base/fonts';
@import 'base/variables';

// Container
.section-feed {
    height: 880px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 375px;
}

.feed-inner {
    box-sizing: border-box;
    height: 880px;
    overflow: scroll;
    padding: 148px 0 0 0;
    position: relative;
    width: 375px;

    &::after {
        content: " ";
        display: block;
        background-image: url(../img/mask.png);
        background-size: 630px;
        background-position: center;
        background-repeat: no-repeat;
        padding-left: 60px;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: -90px;
        width: 100%;
        height: 1000px;
        z-index: 1;
    }
}

.live-feed {
    width: 100%;
    .feed-inner {
        margin: 0 auto;
    }

    .author-image {
        display: none;
    }
}

// Story within Feed
.feed-block {
    background: $c-white;
    box-sizing: border-box;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    color: $c-dark;
    margin: 10px auto;
    max-width: 100%;
    padding: 20px 20px 15px;
    position: relative;
    width: 375px;
    &.feed-block--breaking {
        background: $c-navy;
        color: $c-white;
        .feed-block--header h2 {
            color: $c-white;
            font-family: $f-condensed;
            font-size: 40px;
            font-weight: 600;
            letter-spacing: .02em;
            line-height: 1.05em;
            margin-bottom: 5px;
        }

        .feed-block--authors,
        .feed-block--content {
            color: $c-white;
        }
    }
}


// Content of Feed Story
.feed-block--content {
    color: rgba($c-dark, 0.75);
    padding: 10px 0;
}

.feed-block--content-solo {
    padding: 0 0 10px;
}

.feed-block--story {
    font-family: $f-body;
    font-size: 14px;
    line-height: 1.5em;
    margin: 0;
    white-space: pre-line;

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 30px;

        li {
            margin-top: 0.5em;
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.feed-block--content-solo {
    .feed-block--story {
        font-size: 15px;
        font-weight: 500;
    }
}

.article-image {
    width: calc(100% + 20px);
    margin-bottom: 8px;

    img {
        width: 100%;
    }
}

/**************
 *
 * HEADER
 *
***************/

.feed-block--category,
.feed-block--header-cta {
    color: $c-dark;
    font-family: $f-body;
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 10px;
}

.feed-block--category-breaking {
    color: $c-white;
    position: relative;
    padding-left: 15px;
    transform: translateY(-8px);
    &:before {
        background: $c-red;
        border-radius: 50%;
        content: '';
        height: 7px;
        position: absolute;
        left: 0;
        top: 3px;
        width: 7px;
    }
}

.feed-block--category-image {
    transform: translateY(-8px);
}

.feed-block--overline + .article-image {
    margin-top: -10px;
}

.feed-block--header {
    padding: 0;

    h2  {
        align-items: center;
        color: $c-dark;
        display: flex;
        font-family: $f-headline;
        font-size: 21px;
        font-weight: normal;
        line-height: 1.3em;
        margin: 0;
    }
}

/**************
 *
 * FOOTER
 *
***************/

.feed-block--footer {
    align-items: center;
    display: flex;
    font-family: $f-body;
    font-size: 13px;
    justify-content: flex-start;
    line-height: 1.5em;
    padding: 0;
}

.feed-block--authors {
    color: $c-dark;
    margin-right: 8px;
}

.author-link {
    align-items: center;
    display: flex;
}

.author-image {
    border-radius: 50%;
    height: 24px;
    margin: -1px 10px 1px 0;
    overflow: hidden;
    width: 24px;

    img {
        height: 100%;
        width: 100%;
    }
}

.feed-block--date {
    color: #BDBDBD;
}

.feed-block--controls {
    cursor: pointer;
    height: 24px;
    justify-self: flex-end;
    margin-left: auto;
    padding: 4px;
    position: relative;
    width: 30px;
}

.feed-block--controls-dot {
    left: 50%;
    margin-top: -3px;
    margin-left: -3px;
    top: 50%;
}

.feed-block--controls-dot,
.feed-block--controls-dot:before,
.feed-block--controls-dot:after {
    background-color: #cccccc;
    border-radius: 20px;
    height: 4px;
    position: absolute;
    width: 4px;
}

.feed-block--controls-dot:before,
.feed-block--controls-dot:after {
    content: "";
}

.feed-block--controls-dot:before {
    right: 7px;
    transition: right .3s ease-out;
}

.feed-block--controls-dot:after {
    left: 7px;
    transition: left .3s ease-out;
}

.feed-block--controls:hover {
    .feed-block--controls-dot:before {
        right: -7px;
    }

    .feed-block--controls-dot:after {
        left: -7px;
    }
}

/**************
 *
 * INPUTS
 *
***************/

.feed-block--question,
.feed-block--poll {
    .feed-block--header h2 {
        font-family: 'Sweet Sans Pro';
        font-size: 17px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 5px;
    }
}

/**************
 *
 * POLL
 *
***************/

.feed-block--poll ul {
    padding-left: 0;
}

.feed-block--options {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.feed-block--option {
    background:
        linear-gradient(to left, $c-white 50%, transparent 50%),
        linear-gradient(90deg, $c-white 3px, transparent 1%),
        radial-gradient(#bbbbbb 15%, transparent 16%),
        radial-gradient(#bbbbbb 15%, transparent 16%);
    background-position: right, right, 0 0, 4px 4px;
    background-size: 200% 100%, 4px 4px, 8px 8px, 4px 4px;
    border: 1px solid $c-black;
    color: #48444b;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 15px 15px 13px;
    position: relative;
    transition:all 2s ease;

    .feed-block--label {
        flex-shrink: 0;
        z-index: 1;
    }

    .feed-block--option-background {
        background: rgba($c-blue, 0.25);
        background-position: left, 0 0, 4px 4px;
        background-size: 4px 4px, 8px 8px, 4px 4px;
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
        z-index: 0;
    }
}

.feed-block--option-large {
    .feed-block--label {
        color: #48444b;
    }

    &:first-child .feed-block--label {
        color: $c-white;
    }
}

.feed-block--option:first-child {
    background: rgba($c-blue, 0.8);
    background-position:right bottom;
    background-size: 200% 100%;
    color: $c-white;
    transition:all 2s ease;

    .feed-block--option-background {
        background: $c-blue
    }
}

.feed-block--option-content {
    z-index: 1;
}

.feed-block--option-score {
    font-family: $f-mono;
    font-weight: 600;
    justify-self: flex-end;
    opacity: 0.5;
    margin-left: auto;
    z-index: 1;
}


/**************
 *
 * QUESTION
 *
***************/

.feed-block--input-description {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 15px;}

.feed-block--input {
    font-size: 16px;
    margin: 0;

    &::placeholder {
        color: #AAAAAA;
        opacity: 1;
    }
}

.feed-block--input-box,
.feed-block--input-line {
    background: $c-white;
    border: none;
    border-bottom: 2px solid transparent;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
    font-family: $f-body;
    font-size: 15px;
    padding: 15px 14px 11px;
    width: calc(100% - 22px);

    &:focus, &:active {
        outline: none;
        border-color: $c-blue;
    }
}

/**************
 *
 * SPORTS
 *
***************/

.feed-block--sports {
    padding: 0;
}

.feed-block--sports-teams {
    border-bottom: 1px solid #d8d8d8;
    display: flex;
}

.feed-block--sports-team {
    align-items: center;
    display: flex;
    font-family: $f-body;
    justify-content: center;
    padding: 15px;
    width: 50%;
}

.feed-block--sports-team:first-child {
    border-right: 1px solid #d8d8d8;
    width: calc(50% - 1px);
}

.feed-block--sports-team-details {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    justify-content: center;
    margin-right: 15px;
    width: 75px;
}

.feed-block--sports-team-image {
    width: 50px;
}

.feed-block--sports-team-name {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.02em;
    padding-top: 2px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.feed-block--sports-team-score {
    font-size: 30px;
}

.feed-block--sports-update {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
}

.feed-block--sports-quarter {
    font-family: $f-mono;
    font-size: 11px;
    font-weight: 800;
    margin-right: 10px;
    text-transform: uppercase;
}

.feed-block--sports-timing {
    font-family: $f-body;
    font-size: 13px;
    padding-top: 2px;
}

.feed-block--sports-live {
    color: $c-red;
    font-family: $f-mono;
    font-size: 11px;
    font-weight: bold;
    margin-left: 15px;
    padding-left: 13px;
    position: relative;
    text-transform: uppercase;

    &:before {
        background: $c-red;
        border-radius: 50%;
        content: ' ';
        height: 5px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 5px;
    }
}

.feed-block--sports-details {
    font-family: $f-body;
    font-size: 14px;
    padding: 0 20px 20px 20px;
}

/**************
 *
 * LOADING
 *
***************/

@mixin loadingText {
    background-color: #eeeeee;
    color: transparent;
    position: relative;

    &:after {
        animation: loading 1.5s infinite;
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 100%;
    }
}

.feed-block--loading {
    .feed-block--header h2 {
        display: inline;
        @include loadingText();
    }

    p {
        display: inline;
        @include loadingText();
    }

    .feed-block--content-loading {
        margin: 1em 0;
    }
}

@keyframes loading {
    100% {
      transform: translateX(100%);
    }
}