@import 'base/variables';

.navbar {
    background-color: $c-white;
    min-height: 3.25rem;
    position: relative;
    z-index: 30;

    .container {
        display: flex;
        align-items: stretch;
        position: relative;
        min-height: 3.25rem;
        width: 100%;
    }
}

.navbar-brand {
    align-items: stretch;
    display: flex;
    flex-shrink: 0;

    .navbar-item {
        text-transform: uppercase;
    }
}

.navbar-item {
    align-items: center;
    color: #4a4a4a;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    font-family: "GT Pressura";
    line-height: 1.5;
    padding: .5rem .75rem;
    position: relative;
    text-decoration: none;

    &:hover {
        background-color: #fafafa;
        color: #3273dc;
    }
}

.navbar-burger {
    background: none;
    border: none;
    color: #4a4a4a;
    cursor: pointer;
    display: block;
    height: 3.25rem;
    margin-left: auto;
    outline: none;
    position: relative;
    width: 3.25rem;

    span {
        background-color: currentColor;
        display: block;
        height: 1px;
        left: calc(50% - 8px);
        position: absolute;
        transform-origin: center;
        transition-duration: 86ms;
        transition-property: background-color,opacity,-webkit-transform;
        transition-property: background-color,opacity,transform;
        transition-property: background-color,opacity,transform,-webkit-transform;
        transition-timing-function: ease-out;
        width: 16px;
    }

    span:nth-child(1) {
        top: calc(50% - 6px);
    }

    span:nth-child(2) {
        top: calc(50% - 1px);
    }

    span:nth-child(3) {
        top: calc(50% + 4px);
    }

    &.is-active {
        span:nth-child(1) {
            transform: translateY(5px) rotate(45deg);
        }

        span:nth-child(2) {
            opacity: 0;
        }

        span:nth-child(3) {
            transform: translateY(-5px) rotate(-45deg);
        }
    }
}

.navbar-menu {
    display: none;

    &.is-active {
        display: flex;
    }
}

@media screen and (max-width: 1023px) {
    .navbar-menu {
        background-color: $c-white;
        box-shadow: 0 8px 16px rgba(10,10,10,.1);
        padding: .5rem 0;

        &.is-active {
            display: block;
        }
    }

    .navbar .container {
        display: block;
    }

    .navbar-item {
        flex-grow: 1;
    }
}

@media screen and (min-width: 1024px) {
    .navbar-burger {
        display: none;
    }

    .navbar,
    .navbar-menu {
        align-items: stretch;
        display: flex;
    }

    .navbar-menu {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .navbar-item {
        align-items: center;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
    }
}
