@import 'mixins/font-face';
@import 'base/fonts';
@import 'base/variables';

body {
  background: #f5f4f3;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
    display: flex;
    margin: 0 auto;
    padding: 0;
    position: relative;
}

footer {
  padding: 15px;
  text-align: center;
}

.splash-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.splash {
  margin-bottom: 50px;
  width: 100%;
}

.splash--center {
  text-align: center;
}

.splash h1 {
  font-family: "GT Alpina Beta v5";
  font-size: 22px;
  font-weight: lighter;
}

.splash h2 {
  color: #363636;
  font-family: "GT Pressura";
  font-size: 21px;
  font-weight: normal;
}

.splash form {
  margin: 0 auto;
  width: 25%;
}

.splash .field {
  margin-bottom: 15px;
}

.splash label {
  align-items: center;
  display: flex;
  font-family: $f-mono;
  font-size: 10px;
  text-transform: uppercase;
}

.splash .control {
  flex-grow: 1;
  margin-left: 15px;
}

.splash input {
  font-family: $f-body;
  font-size: 12px;
  padding: 5px 5px 3px 5px;
}

.splash .error {
  color: $c-red;
  font-family: $f-mono;
  font-size: 12px;
  font-weight: bold;
}

.splash button {
  background: #3273dc;
  display: inline-block;
  color: $c-white;
  cursor: pointer;
  font-family: $f-mono;
  line-height: 1.5;
  margin: 10px;
  padding: .5rem .75rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
