// Colors
$c-white: #fff;
$c-black: #000;
$c-dark: #2A262E;
$c-navy: #16183A;
$c-blue: #2271FF;
$c-red: #FF0017;


// Fonts
$f-body: 'Sweet Sans Pro';
$f-condensed: 'Plaak 1 Trial 41-Bold';
$f-headline: 'GT America Condensed';
$f-mono: 'Deck';

// Breakpoints
$xxs:   380px;
$xs:    450px;
$sm:    600px;
$md:    768px;
$lg:    960px;
$xl:    1200px;
$xxl:   1400px;
$xxxl:  1800px;